<template>
  <div :class="marginTop">
    <b-table
      head-variant="light"
      hover
      :items="items"
      :fields="fields"
      striped
      small
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      select-mode="multi"
      selected-variant="primary"
    >
      <template #cell(_)="item">
        <InputCheckbox
          @onChange="() => selectRow(item)"
          :value="isEnabled(item) ? true : false"
        ></InputCheckbox>
      </template>
    </b-table>
    <div class="header-table-operacoes">
      <b-form-group
        label="Por Pagina"
        label-for="per-page-select"
        label-class="fw-medium"
        class="por-pagina"
      >
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          @change="setPorPagina"
          :options="pageOptions"
        ></b-form-select>
      </b-form-group>
      <b-pagination
        @change="setPage"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import TituloService from '@/common/services/titulo/titulo.service';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import helpers from '@/common/utils/helpers';
import { InputCheckbox } from '@/components/inputs';
export default {
  name: 'GridDeTitulos',
  components: {
    InputCheckbox,
  },
  props: {
    filter: { type: Object, default: () => ({}) },
    marginTop: { type: String, default: 'mt-3' },
    atualizarSacados: { type: Function, default: () => ({}) },
  },
  data() {
    return {
      items: [],
      selectedRows: [],
      selectedRowsValue: {},
      sortBy: 'nomeSacado',
      sortDesc: false,
      fields: [
        {
          key: '_',
          label: '',
        },
        {
          key: 'nomeSacado',
          label: 'Sacado',
          sortable: true,
        },
        {
          key: 'nomeFantasia',
          label: 'Empresa',
          sortable: true,
        },
        {
          key: 'titulo',
          label: 'Título',
          sortable: true,
        },
        {
          key: 'dataVencimento',
          label: 'Vencimento',
          sortable: true,
          formatter: (value) => {
            if (value) return helpers.formatarDataBr(value);
          },
        },
        {
          key: 'valor',
          label: 'Valor',
          sortable: true,
          formatter: (value) => {
            if (value) return helpers.formatarValor(value);
          },
        },
        {
          key: 'valorAtualizado',
          label: 'Valor Atualizado',
          sortable: true,
          formatter: (value) => {
            if (value) return helpers.formatarValor(value);
          },
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [
        5,
        10,
        15,
        { value: 100, text: this.$t('GERAL.PAGINACAO') },
      ],
    };
  },
  mounted() {},
  methods: {
    resetSelectRows() {
      this.items = this.items.map((item) => ({ ...item, checked: false }));
      this.selectedRows = [];
    },
    isEnabled(row) {
      return row.item.checked;
    },
    selectRow(row) {
      const items = this.items;
      const indexOf = items.indexOf(row.item);
      items[indexOf].checked = !row.item.checked;
      this.selectedRows = items.filter((x) => x.checked);
      this.items = items;
    },
    setPage(e) {
      this.listar(e, undefined);
      this.currentPage = e;
    },
    setPorPagina(e) {
      this.listar(undefined, e);
      this.perPage = e;
    },
    onFilter() {
      this.listar();
    },
    listar(numeroPagina, porPagina) {
      this.$store.dispatch(START_LOADING);

      const params = Object.keys(this.filter).reduce((prev, current) => {
        if (this.filter[current]) {
          prev[current] = this.filter[current];
        }
        return prev;
      }, {});

      TituloService.listarTitulos({
        ...params,
        NumeroDaPagina: (numeroPagina || this.currentPage) - 1,
        TamanhoDaPagina: porPagina || this.perPage,
      })
        .then((result) => {
          this.totalRows = result.data.data.paginacao.totalDeElementos;
          this.items = result.data.data.itens.map((item) => ({
            ...item,
            checked: false,
          }));
          this.atualizarSacados(result.data.data.sacados);
        })
        .finally(() => this.$store.dispatch(STOP_LOADING));
    },
  },
};
</script>
