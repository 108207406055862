<template>
  <b-card no-body class="p-3">
    <form>
      <div class="d-flex flex-row justify-content-start align-items-center">
        <InputDate
          class="mr-3 input-label"
          :label="$t('INSTRUCOES.DATA_INICIO')"
          v-model="filter.dataVencimentoDe"
        />
        
        <InputDate
          class="mr-3 input-label"
          :label="$t('INSTRUCOES.DATA_FIM')"
          v-model="filter.dataVencimentoAte"
        />
        
        <InputSelect
          class="mr-3 input-label input-select"
          v-model="filter.tipoStatus"
          :label="$t('INSTRUCOES.TIPO_STATUS')"
          :options="colecaoStatus"
          @input="onChangeTipoStatus"
        />
        <div class="pl-2 border-left pt-3 pb-3 ml-auto">
          <div class="d-flex ml-2">
            <b-button
              v-on:click="doClear"
              variant="secondary"
            >
              {{ $t('INSTRUCOES.LIMPAR') }}
            </b-button>
            <b-button
              v-on:click="doFilter"
              class="ml-1"
              variant="primary"
            >
              {{ $t('GERAL.PESQUISAR') }}
            </b-button>

          </div>
        </div>
      </div>
    </form>
    <GridDeSolicitados :filter="filter" ref="gridTitulos" marginTop="mt-5" />
  </b-card>
</template>

<script>
import { InputDate, InputSelect } from '@/components/inputs';
import GridDeSolicitados from './components/GridDeSolicitados.vue';

export default {
  name: 'Solicitados',
  components: { InputDate, InputSelect, GridDeSolicitados },
  data() {
    return {
      filter: {
        dataVencimentoDe: undefined,
        dataVencimentoAte: undefined,
        tipoStatus: 'A REGULARIZAR',
      },
      colecaoTipoInstrucao: [
        { value: 'PRORROGAÇÃO', text: 'Prorrogação' },
        { value: 'ABATIMENTO', text: 'Abatimento' },
        { value: 'NÃO ENCAMINHAR A CARTORIO', text: 'Não Encaminhar a Cartório' },
        { value: 'PEDIDO DE BAIXA', text: 'Pedido de Baixa' },
        { value: 'ENCAMINHAR A PROTESTO', text: 'Encaminhar Protesto' },
        { value: 'SUSTAR PROTESTO', text: 'Sustar Protesto' },
      ],
      colecaoStatus: [
        { value: 'CANCELADAS', text: 'Canceladas' },
        { value: 'REGULARIZADA', text: 'Regularizada' },
        { value: 'A REGULARIZAR', text: 'Regularizar' }
      ],
    };
  },
  mounted() {},
  methods: {
    onChangeTipoInstrucao(valor) {
      this.filter.tipoInstrucao = valor;
    },
    onChangeTipoStatus(valor) {
      this.filter.tipoStatus = valor;
    },
    doFilter() {
      this.$refs.gridTitulos.onFilter();
    },
    doClear() {
      this.filter = {
        dataVencimentoDe: undefined,
        dataVencimentoAte: undefined,
        tipoInstrucao: undefined,
        tipoStatus: undefined,
      };
      this.$refs.gridTitulos.onFilter();
    },
  },
};
</script>

<style scoped>
.input-label {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.input-select {
  min-width: 140px !important;
}
</style>
