<template>
  <b-modal
    ref="modal-parametros"
    size="md"
    :title="modalTitulo ? modalTitulo : 'Enviar Instrução'"
    header-bg-variant="light"
    id="modal-instrucoes"
    @hidden="limparCampos"
  >
    <b-row>
      <b-col>
        <input-text
          ref="motivo"
          placeholder="Descreva o motivo"
          :label="$t('INSTRUCOES.MOTIVO')"
          required
          v-model="motivo"
        />
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <input-currency
          v-if="isAbatimento"
          ref="valor"
          :label="$t('INSTRUCOES.VALOR')"
          required
          v-model="valor"
        />
        <input-date
          v-else
          ref="date"
          :label="$t('INSTRUCOES.DATA')"
          required
          v-model="date"
        />
      </b-col>
    </b-row>

    <template v-slot:modal-footer>
      <b-button variant="primary" @click="salvar">{{
        $t('INSTRUCOES.SALVAR')
      }}</b-button>
      <b-button variant="light" @click="fecharModal">{{
        $t('INSTRUCOES.VOLTAR')
      }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import { InputText, InputCurrency, InputDate } from '@/components/inputs';
import InstrucoesService from '@/common/services/instrucoes/instrucoes.service';
import helpers from "@/common/utils/helpers";
export default {
  name: 'ModalInstrucoes',
  components: { InputText, InputCurrency, InputDate },
  data() {
    return {
      motivo: null,
      date: null,
      valor: null,
    };
  },
  props: {
    mostrarModal: { type: Boolean, default: false },
    modalTitulo: { type: String, default: null },
    tipoInstrucao: { type: String, default: null },
    data: { type: Array, default: null },
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (modalEvent, modalId) => {
      if (
        modalEvent.trigger == 'esc' ||
        modalEvent.trigger == 'backdrop' ||
        modalEvent.trigger == 'headerclose'
      ) {
        if (modalId == 'modal-instrucoes') this.fecharModal();
      }
    });
  },
  computed: {
    isAbatimento() {
      return this.tipoInstrucao?.toLowerCase() === 'a';
    },
  },
  methods: {
    limparCampos() {
      this.motivo = null;
      this.date = null;
      this.valor = null;
    },
    fecharModal() {
      this.$emit('fechar-modal');
      this.$emit('reset-select-rows');
      //realizado isso, pq antes ele limpava o valor e antes de fechar ele atualizava os inputs e ficavam com status de erro
      setTimeout(() => {
        this.limparCampos();
      }, 300);
    },
    validarFormulario() {
      let arValidation = [];
      let refs = this.$refs;

      for (const key in refs) {
        if (Object.hasOwnProperty.call(refs, key)) {
          if (refs[key]?.valid) {
            arValidation.push(refs[key].valid());
          }
        }
      }
      return arValidation.filter((elem) => elem == false).length == 0;
    },
    getCustomValue(item) {
      return {
        data: this.isAbatimento ? item.date : this.date,
        valor: this.isAbatimento ? helpers.removerValor(this.valor) : item.valor,
      };
    },
    salvar() {
      if (!this.validarFormulario()) return;

      const items = this.data.map((item) => ({
        codigoEmpresa: item.codigoEmpresa,
        codigoFilial: item.codigoFilial,
        sequencialTitulo: item.sequencialTitulo,
        titulo: item.modalTitulo,
        instrucao: this.tipoInstrucao,
        motivo: this.motivo,
        ...this.getCustomValue(item),
      }));
      InstrucoesService.enviarInstrucao({ items }, this);
    },
  },
  watch: {
    mostrarModal(valor) {
      if (valor) this.$refs['modal-parametros'].show();
      else this.$refs['modal-parametros'].hide();
    },
  },
};
</script>

<style></style>
