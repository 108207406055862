<template>
  <div>
    <b-tabs v-model="tabIndex" content-class="mt-3 tab-autenticacao">
      <b-tab
        :title="$t('INSTRUCOES.TITULOS')"
        active
        :title-link-class="linkClass(0)"
      >
        <Titulos />
      </b-tab>
      <b-tab
        :title="$t('INSTRUCOES.SOLICITADOS')"
        :title-link-class="linkClass(1)"
      >
        <Solicitados />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { DEFINIR_BREADCRUMB } from '@/store/breadcrumbs.module';
import Titulos from "./titulos/Titulos.vue";
import Solicitados from "./solicitados/Solicitados.vue";

export default {
  name: "InstrucoesTabs",
  components: {
    Titulos,
    Solicitados,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t('MENU.INSTRUCOES') },
      { titulo: this.$t('INSTRUCOES.LISTAR_INSTRUCOES') },
    ]);
  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["tabs-autenticacao", "active-tab"];
      } 
      else {
        return ["tabs-autenticacao", "inactive-tab"];
      }
    },
  },
};
</script>

<style lang="scss">
.nav-link {
  padding: 0.8rem 3rem;
  margin-right: 0.5rem;
}
.tab-autenticacao {
  min-height: 500px;
}
.active-tab {
  background-color: #a08c6a !important;
  color: white !important;
}

.inactive-tab {
  background-color: white !important;
}
</style>
