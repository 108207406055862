<template>
  <div :class="[marginTop, marginBottom]">
    <b-button
      class="mr-2 border text-primary"
      variant="outline-light"
      @click="selecionarProrrogacao"
      :disabled="!editable"
    >
      <i class="fas fa-indent icon-color"></i>
      {{ $t("INSTRUCOES.PRORROGACAO") }}</b-button
    >
    <b-button
      class="mr-2 border text-primary"
      variant="outline-light"
      @click="selecionarAbatimento"
      :disabled="!editable"
    >
      <i class="fas fa-indent icon-color"></i>
      {{ $t("INSTRUCOES.ABATIMENTO") }}</b-button
    >
    <b-button
      class="mr-2 border text-primary"
      variant="outline-light"
      @click="selecionarNaoEncaminharCartorio"
      :disabled="!editable"
    >
      <i class="fas fa-indent icon-color"></i>
      {{ $t("INSTRUCOES.NAO_ENCAMINHAR_CARTORIO") }}</b-button
    >
    
    <b-button
      class="mr-2 border text-primary"
      variant="outline-light"
      @click="selecionarEncaminharProtesto"
      :disabled="!editable"
    >
      <i class="fas fa-indent icon-color"></i>
      {{ $t("INSTRUCOES.ENCAMINHAR_PROTESTO") }}</b-button
    >
    <b-button
      class="mr-2 border text-primary"
      variant="outline-light"
      @click="selecionarSusterProtesto"
      :disabled="!editable"
    >
      <i class="fas fa-indent icon-color"></i>
      {{ $t("INSTRUCOES.SUSTAR_PROTESTO") }}</b-button
    >
  </div>
</template>

<script>
export default {
  name: "ButtonGroup",
  components: {},
  props: {
    marginTop: { type: String, default: "mt-4" },
    marginBottom: { type: String, default: "mb-4" },
    editable: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    selecionarAbatimento() {
      this.$emit("abatimento", {
        tipoInstrucao: "A",
        titulo: "Instrução Abatimento",
      });
    },
    selecionarEncaminharProtesto() {
      this.$emit("encaminhar-protesto", {
        tipoInstrucao: "E",
        titulo: "Instrução Encaminhar Protesto",
      });
    },
    selecionarNaoEncaminharCartorio() {
      this.$emit("nao-encaminhar-cartorio", {
        tipoInstrucao: "C",
        titulo: "Instrução Não Encaminhar a Cartório",
      });
    },
    selecionarPedidoBaixa() {
      this.$emit("pedido-baixa", {
        tipoInstrucao: "B",
        titulo: "Instrução Pedido de Baixa",
      });
    },
    selecionarProrrogacao() {
      this.$emit("prorrogacao", {
        tipoInstrucao: "P",
        titulo: "Instrução Prorrogação",
      });
    },
    selecionarSusterProtesto() {
      this.$emit("sustar-protesto", {
        tipoInstrucao: "S",
        titulo: "Instrução Sustar Protesto",
      });
    },
  },
  watch: {},
};
</script>

<style>
.icon-color {
  color: #a08c6a !important;
  margin-right: 0.3rem;
}
</style>
