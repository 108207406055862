import ApiService from '@/common/api/api.service';
import Swal from 'sweetalert2';
import { START_LOADING, STOP_LOADING } from '@/store/Store';

const InstrucoesService = {
  enviarInstrucao(form, self) {
    self.$store.dispatch(START_LOADING);
    let instrucao = ApiService.post(`Instrucoes/enviar`, { ...form });
    instrucao
      .then((response) => {
        if (response.data.success)
          Swal.fire({
            title: self.$t('GERAL.SUCCESSO'),
            text: '',
            icon: 'success',
            confirmButtonText: self.$t('GERAL.OK'),
          });
      })
      .finally(() => {
        self.fecharModal();
        self.$store.dispatch(STOP_LOADING);
      });
    return instrucao;
  },
  listarSolicitados(form) {
    return ApiService.post(`/Instrucoes/ConsultaInstrucoesBancarias`, form);
  },
  formatarValor(valor) {
    if (!valor) return;
    let valor1 = valor.split(' ');
    let valor2 = valor1[1].replace('.', '');
    let valor3 = valor2.replace('.', '');
    let valorFinal = valor3.replace(',', '.');
    return valorFinal;
  },
};

export default InstrucoesService;
