<template>
  <b-card no-body class="p-3 mt-3">
    <form ref="anyName" class="mb-3">
      <div class="d-flex align-items-center">
        <div class="pr-3">
          <input-date
            class="tamanho-label"
            :label="$t('INSTRUCOES.DATA_INICIO')"
            v-model="filter.dataVencimentoDe"
          />
        </div>
        <div class="pr-3">
          <input-date
            class="tamanho-label"
            :label="$t('INSTRUCOES.DATA_FIM')"
            v-model="filter.dataVencimentoAte"
          />
        </div>
        <div class="pr-3" v-if="opcoesSacados.length > 0">
          <InputMultiSelect
            :showLabels="false"
            :value="filter.sacados"
            :options="opcoesSacados"
            :searchable="true"
            :optionHeight="30"
            :multiple="true"
            :onChange="onChangeSacados"
            :label="$t(`SACADO.SACADO`)"
            placeholder="Nome do Sacado"
          />
        </div>
        <div class="pr-3" v-if="opcoesSacados.length > 0">
          <input-text
            class="tamanho-label"
            ref="nossoNumero"
            :label="$t('INSTRUCOES.TITULO')"
            v-model="filter.titulo"
            placeholder="Número do título"
          />
        </div>
        <div class="pl-2 border-left pt-3 pb-3 ml-auto">
          <div class="d-flex ml-2">
            <b-button
              v-on:click="doClear"
              variant="secondary"
            >
              {{ $t('INSTRUCOES.LIMPAR') }}
            </b-button>
            <b-button
              v-on:click="doFilter"
              class="ml-1"
              variant="primary"
            >
              {{ $t('GERAL.PESQUISAR') }}
            </b-button>
          </div>
        </div>
      </div>
    </form>

    <button-group
      :marginBottom="'mb-2'"
      :editable="editable"
      @abatimento="mostrarModalAbatimento"
      @encaminhar-protesto="mostrarModalEncaminharProtesto"
      @nao-encaminhar-cartorio="mostrarModalNaoEncaminharACartorio"
      @pedido-baixa="mostrarModalPedidoDeBaixa"
      @prorrogacao="mostrarModalProrrogacao"
      @sustar-protesto="mostrarModalSustarProtesto"
    ></button-group>

    <GridDeTitulos
      :atualizarSacados="atualizarSacados"
      :filter="filter"
      ref="gridTitulos"
      marginTop=""
    />

    <modal-instrucoes
      :mostrarModal="mostrarModal"
      :data="selectedRows"
      :tipoInstrucao="tipoInstrucao"
      :modalTitulo="modalTitulo"
      @fechar-modal="toggleModal"
      @reset-select-rows="resetSelectRows"
    ></modal-instrucoes>
  </b-card>
</template>

<script>
import InputDate from '@/components/inputs/InputDate';
import InputText from '@/components/inputs/InputText';
import InputMultiSelect from '@/components/inputs/InputMultiSelect';
import ButtonGroup from './components/ButtonGroup.vue';
import ModalInstrucoes from './components/ModalInstrucoes.vue';
import GridDeTitulos from './components/GridDeTitulos.vue';

export default {
  name: 'Titulos',
  components: {
    GridDeTitulos,
    InputDate,
    InputText,
    ButtonGroup,
    ModalInstrucoes,
    InputMultiSelect,
  },
  data() {
    return {
      filter: {
        dataVencimentoDe: undefined,
        dataVencimentoAte: undefined,
        titulo: undefined,
        sacados: [],
      },
      opcoesSacados: [],
      mostrarModal: false,
      tipoInstrucao: null,
      modalTitulo: null,
      selectedRows: [],
    };
  },
  mounted() {
    this.$watch('$refs.gridTitulos.selectedRows', (value) => {
      this.selectedRows = value;
    });
  },
  computed: {
    editable() {
      return this.selectedRows?.length > 0;
    },
  },
  methods: {
    resetSelectRows(){
      this.$refs.gridTitulos.resetSelectRows()
    },
    doFilter() {
      this.$refs.gridTitulos.onFilter();
    },
    doClear() {
      this.filter = {
        dataVencimentoDe: undefined,
        dataVencimentoAte: undefined,
        titulo: undefined,
        sacados: [],
      };
      this.$refs.gridTitulos.onFilter();
    },
    atualizarSacados(sacados) {
      this.opcoesSacados = sacados;
    },
    onChangeSacados(value) {
      this.filter.sacados = value;
    },
    toggleModal() {
      this.mostrarModal = !this.mostrarModal;
    },
    mostrarModalAbatimento(valor) {
      this.toggleModal();
      this.tipoInstrucao = valor.tipoInstrucao;
      this.modalTitulo = valor.titulo;
    },
    mostrarModalEncaminharProtesto(valor) {
      this.toggleModal();
      this.tipoInstrucao = valor.tipoInstrucao;
      this.modalTitulo = valor.titulo;
    },
    mostrarModalNaoEncaminharACartorio(valor) {
      this.toggleModal();
      this.tipoInstrucao = valor.tipoInstrucao;
      this.modalTitulo = valor.titulo;
    },
    mostrarModalPedidoDeBaixa(valor) {
      this.toggleModal();
      this.tipoInstrucao = valor.tipoInstrucao;
      this.modalTitulo = valor.titulo;
    },
    mostrarModalProrrogacao(valor) {
      this.toggleModal();
      this.tipoInstrucao = valor.tipoInstrucao;
      this.modalTitulo = valor.titulo;
    },
    mostrarModalSustarProtesto(valor) {
      this.toggleModal();
      this.tipoInstrucao = valor.tipoInstrucao;
      this.modalTitulo = valor.titulo;
    },
  },
};
</script>

<style scoped>
.tamanho-label {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.tamanho-label-button {
  font-size: 12px;
  text-decoration: none;
}
</style>
