<template>
  <div :class="marginTop">
    <b-table
      head-variant="light"
      hover
      :items="items"
      :fields="fields"
      striped
      small
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      select-mode="multi"
      selected-variant="primary"
    >
      <template #cell(_)="item">
        <b-checkbox @change="selectRow(item)"></b-checkbox>
      </template>
    </b-table>
    <div class="header-table-operacoes">
      <b-form-group
        label="Por Pagina"
        label-for="per-page-select"
        label-class="fw-medium"
        class="por-pagina"
      >
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          @change="setPorPagina"
          :options="pageOptions"
        ></b-form-select>
      </b-form-group>
      <b-pagination
        @change="setPage"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import InstrucoesService from '@/common/services/instrucoes/instrucoes.service';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import helpers from '@/common/utils/helpers';

export default {
  name: 'GridDeSolicitados',
  props: {
    filter: { type: Object, default: () => ({}) },
    marginTop: { type: String, default: 'mt-3' },
  },
  data() {
    return {
      items: [],
      selectedRows: [],
      sortBy: 'nome_sacado',
      sortDesc: false,
      fields: [
        {
          key: 'nome_sacado',
          label: 'Sacado',
          sortable: true,
        },
        // {
        //   key: 'nomeFantasia',
        //   label: 'Empresa',
        //   sortable: true,
        // },
        {
          key: 'titulo',
          label: 'Título',
          sortable: true,
        },
        {
          key: 'data_vencimento',
          label: 'Data',
          sortable: true,
          formatter: (value) => {
            if (value) return helpers.formatarDataBr(value);
          },
        },
        // {
        //   key: 'observacaoEnviada',
        //   label: 'Observação Enviada',
        //   sortable: true,
        // },
        {
          key: 'tipo_instrucao',
          label: 'Instrução',
          sortable: true,
        },
        // {
        //   key: 'status_instrucao',
        //   label: 'Status',
        //   sortable: true,
        // },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [
        5,
        10,
        15,
        { value: 100, text: this.$t('GERAL.PAGINACAO') },
      ],
    };
  },
  mounted() {},
  methods: {
    selectRow(row) {
      const indexOf = this.selectedRows.indexOf(row.item);
      if (indexOf >= 0) {
        this.selectedRows = this.selectedRows.filter(
          (x) => x.titulo !== row.item.titulo
        );
      } else {
        this.selectedRows.push(row.item);
      }
    },
    setPage(e) {
      this.listar(e, undefined);
      this.currentPage = e;
    },
    setPorPagina(e) {
      this.listar(undefined, e);
      this.perPage = e;
    },
    onFilter() {
      this.listar();
    },
    listar(numeroPagina, porPagina) {
      this.$store.dispatch(START_LOADING);

      const params = Object.keys(this.filter).reduce((prev, current) => {
        if (this.filter[current]) {
          prev[current] = this.filter[current];
        }
        return prev;
      }, {});

      InstrucoesService.listarSolicitados({
        ...params,
        NumeroDaPagina: (numeroPagina || this.currentPage) - 1,
        TamanhoDaPagina: porPagina || this.perPage,
      })
        .then((result) => {
          this.totalRows = result.data.data.paginacao.totalDeElementos;
          this.items = result.data.data.itens;
        })
        .finally(() => this.$store.dispatch(STOP_LOADING));
    },
  },
};
</script>

<style></style>
